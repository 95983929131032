import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row, Select, Tag } from "antd";
import { Tooltip } from "antd";
import { get, isEmpty } from "lodash";

const Option = Select.Option;

function CustomSelect(props) {
  const {
    value,
    display,
    onChange,
    placeholder,
    data,
    keyAtr,
    valueAtr,
    logoAtr,
    fetch,
    fetching,
    ref,
    rightAtr,
    setSelected,
    mode
  } = props;

  useEffect(() => {
    if (!isEmpty(value)) {
      setSelected(data.find(a => a[keyAtr] === value));
    }
  }, [value]);

  return (
    <Tooltip
      placement="top"
      mouseEnterDelay={1}
      mouseLeaveDelay={0.5}
      overlayStyle={{ background: "white" }}
      title={
        <Button
          size={props.size}
          style={{ backgroundColor: "Transparent", color: "white" }}
          icon="retweet"
          onClick={fetch}
        >
          Refresh dropdown
        </Button>
      }
      trigger="hover"
    >
      <Select
        data-testid={props["data-testid"]}
        style={{ width: "100%" }}
        value={value}
        display={display}
        showSearch
        ref={ref}
        allowClear={true}
        onChange={onChange}
        placeholder={placeholder || "Select an option"}
        filterOption={(input, option) =>
          option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        {...props}
      >
        {props.children ||
          data.map(c => (
            <Option
              data-testid={get(c, keyAtr)}
              key={get(c, keyAtr)}
              value={get(c, keyAtr)}
              title={get(c, valueAtr)}
            >
              {mode === "multiple" ? (
                <Tag color={get(c, "color", "#2db7f5")}>
                  {get(c, valueAtr) || get(c, keyAtr)}
                </Tag>
              ) : (
                get(c, valueAtr) || get(c, keyAtr)
              )}

              {!!get(c, logoAtr) && (
                <span role="img" style={{ marginLeft: 2 }}>
                  {get(c, logoAtr, "")}
                </span>
              )}

              {!!get(c, rightAtr) && (
                <span style={{ float: "right", marginLeft: 10 }}>
                  {get(c, rightAtr, "")}
                </span>
              )}
            </Option>
          ))}
      </Select>
    </Tooltip>
  );
}

CustomSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  data: PropTypes.array.isRequired,
  keyAtr: PropTypes.string,
  valueAtr: PropTypes.string
};

CustomSelect.defaultProps = {
  keyAtr: "key",
  setSelected: () => null,
  valueAtr: "value"
};
export default CustomSelect;
