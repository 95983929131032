import React from "react";
import { Result, Button } from "antd";

function Success(props) {
  const isVendor = window.location.hostname.includes("vendor");
  return (
    <Result
      status="success"
      title={`Added ${isVendor ? "Vendor" : "Client"} Details Successfully `}
      subTitle="Thanks You."
    />
  );
}

export default Success;
