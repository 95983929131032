import React from "react";
import VendorCont from "./VendorCont";
import ClientCont from './ClientCont'

function SubDomainRouter(props) {
  const domain = window.location.hostname.split(".")[0] || "vendor";
  console.log({ domain });
  return domain === "vendor" ? <VendorCont /> : <ClientCont />;
}

export default SubDomainRouter;
