import { useEffect } from 'react'

export default function useTitle (title) {
  useEffect(
    () => {
      document.title = title + ' - PMS'
      return () => {
        document.title = 'PMS - MONDIALE'
      }
    },
    [title],
  )
}
