import React from "react";
import { Router } from "@reach/router";
import Main from "./Components/Main";
import Success from "./Components/Success";
import SubDomainRouter from "./Components/SubDomainRouter";

export default function AppRouter() {
  return (
    <Router>
      <Main path="/" default>
        <SubDomainRouter path="/" default />
        <Success path="/Success" />
      </Main>
    </Router>
  );
}
