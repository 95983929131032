import fetch from "isomorphic-fetch";
import { handleErrors, handleResponse } from "../element/Utils";

const apiUrl = "https://vendor.mondiale.com/api/";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};
export const ClientApi = {
  create: data => {
    return fetch(`${apiUrl}client/`, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(handleErrors)
      .then(handleResponse);
  }
};
