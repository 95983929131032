import React, {Component} from 'react'

const withData = ({data = []}) => WrappedComponent => {
  return class extends Component {
    render() {
      return <WrappedComponent data={data} {...this.props} />
    }
  }
}

export default withData
