import React, { useState } from "react";
import { get } from "lodash";
import useTitle from "../element/useTitle";
import { checkForm } from "../element/Utils";
import { navigate } from "@reach/router";
import Client from "./Client";
import { ClientApi } from "../api/Client";

function ClientCont(props) {
  //const { host } = props;
  const [host] = useState(window.location.hostname);
  const isMondiale = host.includes("mondiale");
  const isMMEA = host.includes("mmea");
  const isCleanMist = host.includes("cleanmist");
  const isClearFog = host.includes("clearfog");
  const isSouter = host.includes("souter");

  const formRef = React.createRef();
  const [submitting, setSubmitting] = useState(false);

  useTitle("Client Registration Form");

  const payload = {
    realm: isMondiale ? "Mondiale Group" :
        isMMEA ? "MMEA Group" :
            isCleanMist ? "Clean Mist Australia" :
              isClearFog ? "Clear Fog" :
                  "Souter Trading"
  };

  async function submit() {
    let newObject = await checkForm(formRef);
    let data = { ...newObject, ...payload };
    if (get(data, "accounts.isSameAsRep"))
      data.accounts = { ...data.accounts, ...data.representativeContact };

    if (get(data, "coordinator.isSameAsRep"))
      data.coordinator = { ...data.coordinator, ...data.representativeContact };

    setSubmitting(true);
    try {
      const submitted = await ClientApi.create(data);
      if (submitted) navigate("/Success");
    } catch (e) {
      console.log(e);
    }
    setSubmitting(false);
  }

  return (
    <Client
      {...{
        submit,
        submitting,
        host,
        isMondiale,
        isMMEA,
        isCleanMist,
        isClearFog,
        isSouter
      }}
      ref={formRef}
    />
  );
}

export default ClientCont;
