// export default {
export async function handleErrors (response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    throw await response.json()
  }
}

export async function handleResponse (response) {
  return await response.json()
}

export function checkForm (formRef) {
  return new Promise((res, rej) => {
    formRef.current.validateFieldsAndScroll((err, values) => {
      if (!err) {
        res(values)
      }
    })
  })
}
