import React from "react";
import logoCleanMist from "../logos/cleanMist.png";
import logoClearFog from "../logos/clearFog.png";
import logoMondiale from "../logos/MondialeGroupLogo.png";
import logoMMEA from "../logos/mmea.png";
import logoSouter from "../logos/SouterLogo.png";

function Header(props) {
  const { host } = props;
    const isMondiale = host.includes("mondiale");
    const isMMEA = host.includes("mmea");
    const isClearFog = host.includes("clearfog");
    const isCleanMist = host.includes("cleanmist");
  const domain = window.location.hostname.split(".")[0] || "vendor";
  const isVendor = domain === "vendor";
  return (
    <div
      style={{
        padding: 24,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <img
        src={ isMondiale ? logoMondiale : isMMEA ? logoMMEA : isCleanMist ? logoCleanMist : isClearFog ? logoClearFog : logoSouter }
        alt="logo"
        style={{
          width: "70%",
          height: "70%",
          margin: "auto",
          flex: 1,
          maxWidth: "500px"
        }}
      />
      <span
        style={{
          flex: 1,
          textAlign: "center",
          margin: "20px 0px",
          fontWeight: 700,
          fontSize: "2rem"
        }}
      >
        {isVendor ? "VENDOR" : "CLIENT"} REGISTRATION FORM
      </span>
      <span
        style={{
          flex: 1,
          margin: "20px 0px",
          fontWeight: 300,
          maxWidth: "700px!important",
          fontSize: "1.14rem"
        }}
      >
        Kindly complete the following form in order to commence the registration
        process of your company with {" "}
        {isMondiale ?
            "MONDIALE Group" :
            isMMEA ?
                "MMEA Group" :
                isCleanMist ?
                    "Clean Mist Australia" :
                        isClearFog ?
                            "Clear Fog Ltd" :
            "Souter Trading International Ltd"}. Should you encounter
        any difficulties with the submission of the form, please email:{" "}
        {isMondiale ?
            `${isVendor ? "vendor" : "client"}-reg@mondiale.com` :
            isMMEA ?
                `${isVendor ? "vendor" : "client"}-reg@mmeagroup.com` :
                isCleanMist ?
                    `${isVendor ? "vendor" : "client"}-reg@cleanmist.com.au` :
                    isClearFog ?
                        `${isVendor ? "vendor" : "client"}-reg@clearfog.co.uk` :
          `${isVendor ? "vendor" : "client"}-reg@souter-trading.com`}
      </span>
    </div>
  );
}

export default Header;
