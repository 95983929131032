import React, {Component} from 'react'

const withSort = WrappedComponent => {
  return class extends Component {
    sort = data => {
      if (Array.isArray(data) && data.length > 0) {
        // if (sortBy) {
        //   data = data.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
        // }
        if (typeof data[0] === 'string' || typeof data[0] === 'number') {
          data = data.sort()
        } else if (typeof data[0] === 'object') {
          if (Object.keys(data[0]).includes('value')) {
            data = data.sort((a, b) => (a.value > b.value ? 1 : -1))
          } else if (Object.keys(data[0]).includes('name')) {
            data = data.sort((a, b) => (a.name > b.name ? 1 : -1))
          } else {
            let sortKey = Object.keys(data[0])[0]
            data = data.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : -1))
          }
        }
      }
      return data
    }

    render() {
      const {data} = this.props
      const sorted = this.sort(data)
      return <WrappedComponent {...this.props} data={sorted} />
    }
  }
}

export default withSort
