import React, { useEffect, useState } from "react";
import country from "../country";
import { Col, Form, Input } from "antd";
import { defaultFormItemLayout } from "../element/FormItems";
import { compose } from "recompose";
import withData from "../element/withData";
import withSort from "../element/HOC/withSort";
import CustomSelect from "../element/CustomSelect";

function PhoneCountryComponent(props) {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const { phonePath, phoneName = "Telephone Number" } = props;

  const CountrySelect = compose(
    withData({ data: country }),
    withSort
  )(CustomSelect);

  const numberPattern = /\d+/g;
  const phone = getFieldValue(phonePath);

  const [countryCode, setCountryCode] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if ([phoneNumber, countryCode].every(i => !!i)) {
      const countryNumber = country.find(i => i.key === countryCode).Dial;
      const rawCode = [countryNumber, areaCode, phoneNumber].join("");
      const code = rawCode.match(numberPattern).join([]);

      setFieldsValue({ [phonePath]: code });
    } else {
      setFieldsValue({ [phonePath]: "" });
    }
  }, [areaCode, phoneNumber, countryCode]);

  return (
    <div>
      <Col span={12}>
        <Form.Item
          {...defaultFormItemLayout}
          validateStatus={phone ? "" : countryCode && "error"}
          help={phone ? "" : countryCode && "This Fields Required!"}
          label={phoneName}
        >
          {getFieldDecorator(phonePath)(
            <Input type="hidden" style={{ width: "20%" }} />
          )}

          <Input.Group compact>
            <CountrySelect
              placeholder={"Country"}
              logoAtr={"Unicode"}
              rightAtr={"Dial"}
              value={countryCode}
              style={{ width: "45%" }}
              onChange={e => setCountryCode(e)}
            />

            <Input
              required
              style={{ width: "15%" }}
              placeholder={"Area"}
              onChange={e => setAreaCode(e.target.value)}
            />
            <Input
              required
              style={{ width: "40%" }}
              placeholder={"Phone Number"}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Input.Group>
        </Form.Item>
      </Col>
    </div>
  );
}

export default PhoneCountryComponent;
