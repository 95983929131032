import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row
} from "antd";
import {
  defaultFormItemLayout,
  fullWidthFormItemLayout,
  rules
} from "../element/FormItems";
import { compose } from "recompose";
import withSort from "../element/HOC/withSort";
import CustomSelect from "../element/CustomSelect";
import withData from "../element/withData";
import country from "../country";
import currency from "../currency";
import moment from "moment";
import PhoneCountryComponent from "./PhoneCountryComponent";

function Client(props) {
  const { getFieldDecorator } = props.form;
  const { submit, submitting, host, isMondiale, isMMEA, isCleanMist, isClearFog, isSouter } = props;

  const [isAccountContactSameAsRep, setIsAccountContactSameAsRep] = useState(
    false
  );
  const [isCoordinatorContactSameAsRep, setIsCoordinatorSameAsRep] = useState(
    false
  );

  const Label = ({ text }) => (
    <div
      style={{
        fontSize: "1.714rem",
        margin: "1em 0em 0.5em 0em"
      }}
    >
      {text}
    </div>
  );

  const CountrySelect = compose(
    withData({ data: country }),
    withSort
  )(CustomSelect);

  const CurrencySelect = compose(
    withData({ data: currency }),
    withSort
  )(CustomSelect);

  return (
    <Form>
      <Label text={"Business Details"} />

      <Row>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Registered Name"}>
            {getFieldDecorator("business.registeredName", {
              rules: rules.input
            })(<Input placeholder="Registered Name" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Trading Name"}>
            {getFieldDecorator("business.tradingName")(
              <Input placeholder="Trading Name" />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Company No"}>
            {getFieldDecorator("business.registrationNo")(
                <Input placeholder="Registration Number" />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...defaultFormItemLayout} label={(isSouter || isClearFog) ? "VAT Number" : isCleanMist ? "ABN" : "Tax Number"}>
            {getFieldDecorator("business.taxNo")(
              <Input placeholder={(isSouter || isClearFog) ? "VAT Number" : isCleanMist ? "ABN" : "Tax Number"} />
             )}
          </Form.Item>
        </Col>
          {isMMEA && isMondiale && (
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"DUNS Number"}>
            {getFieldDecorator("business.dunsNo")(
              <Input placeholder="DUNS Number" />
            )}
          </Form.Item>
        </Col>
          )}
      </Row>
      <Divider />

      <Label text={"Registered Address"} />
      <Row>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Country"}>
            {getFieldDecorator("registeredAddress.country", {
              rules: rules.input,
                initialValue: isCleanMist ? "Australia" : (isClearFog || isSouter) ? "United Kingdom" : "",
            })(<CountrySelect placeholder="Country" logoAtr={"Unicode"} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Address 1"}>
            {getFieldDecorator("registeredAddress.address.line1", {
              rules: rules.input
            })(<Input placeholder="Address" />)}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Address 2"}>
            {getFieldDecorator("registeredAddress.address.line2")(
              <Input placeholder="Address" />
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"City"}>
            {getFieldDecorator("registeredAddress.city", {
              rules: rules.input
            })(<Input placeholder="City" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...defaultFormItemLayout}
            label={(isMondiale || isMMEA || isCleanMist) ? "State" : "County"}
          >
            {getFieldDecorator("registeredAddress.state", {
              rules: rules.input
            })(<Input placeholder={(isMondiale || isMMEA || isCleanMist) ? "State" : "County"} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Postcode"}>
            {getFieldDecorator("registeredAddress.postcode", {
              rules: rules.input
            })(<Input placeholder="Postcode" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <PhoneCountryComponent
          {...{
            form: props.form,
            phonePath: "registeredAddress.telephoneNo"
          }}
        />

        <PhoneCountryComponent
          {...{
            form: props.form,
            phonePath: "registeredAddress.faxNo",
            phoneName: "Fax Number"
          }}
        />
      </Row>
      <Divider />

      <Label text={"Sales Representative"} />
      <Row>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Name"}>
            {getFieldDecorator("representativeContact.name", {
              rules: rules.input
            })(<Input placeholder="Name" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Position"}>
            {getFieldDecorator("representativeContact.position", {
              rules: rules.input
            })(<Input placeholder="Position" />)}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Email"}>
            {getFieldDecorator("representativeContact.email", {
              rules: rules.input
            })(<Input placeholder="Email" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {/*<Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Country"}>
            {getFieldDecorator("representativeContact.country", {
              rules: rules.input
            })(<CountrySelect placeholder="Country" logoAtr={"Unicode"} />)}
          </Form.Item>
        </Col>*/}
        <PhoneCountryComponent
          {...{
            form: props.form,
            phonePath: "representativeContact.telephoneNo"
          }}
        />

        <PhoneCountryComponent
          {...{
            form: props.form,
            phonePath: "representativeContact.mobileNo",
            phoneName: "Mobile Number"
          }}
        />
      </Row>

      <Divider />

      <Label text={"Accounts Contact"} />
      <Row>
        <Col span={12}>
          <Form.Item
            {...defaultFormItemLayout}
            label={"Same as Sales"}
          >
            {getFieldDecorator("accounts.isSameAsRep")(
              <Checkbox
                onChange={({ target }) =>
                  setIsAccountContactSameAsRep(target.checked)
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      {!isAccountContactSameAsRep && (
        <>
          <Row>
            <Col span={12}>
              <Form.Item {...defaultFormItemLayout} label={"Name"}>
                {getFieldDecorator("accounts.name")(
                  <Input placeholder="Name" />
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item {...defaultFormItemLayout} label={"Position"}>
                {getFieldDecorator("accounts.position")(
                  <Input placeholder="Position" />
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item {...defaultFormItemLayout} label={"Email"}>
                {getFieldDecorator("accounts.email")(
                  <Input placeholder="Email" />
                )}
              </Form.Item>
            </Col>

            {/*<Col span={12}>
              <Form.Item {...defaultFormItemLayout} label={"Country"}>
                {getFieldDecorator("accounts.country")(
                    <CountrySelect placeholder="Country" logoAtr={"Unicode"} />
                )}
              </Form.Item>
            </Col>*/}
          </Row>

          <Row>
            <PhoneCountryComponent
              {...{
                form: props.form,
                phonePath: "accounts.telephoneNo"
              }}
            />

            <PhoneCountryComponent
              {...{
                form: props.form,
                phonePath: "accounts.mobileNo",
                phoneName: "Mobile Number"
              }}
            />
          </Row>
        </>
      )}
      <Divider />

      <Label text={"Shipping/Logistics Coordinator"} />
      <Row>
        <Col span={12}>
          <Form.Item
            {...defaultFormItemLayout}
            label={"Same as Sales"}
          >
            {getFieldDecorator("coordinator.isSameAsRep")(
              <Checkbox
                onChange={({ target }) =>
                  setIsCoordinatorSameAsRep(target.checked)
                }
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      {!isCoordinatorContactSameAsRep && (
        <>
          <Row>
            <Col span={12}>
              <Form.Item {...defaultFormItemLayout} label={"Name"}>
                {getFieldDecorator("coordinator.name")(
                  <Input placeholder="Name" />
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item {...defaultFormItemLayout} label={"Position"}>
                {getFieldDecorator("coordinator.position")(
                  <Input placeholder="Position" />
                )}
              </Form.Item>
            </Col>

            {/*<Col span={12}>
                <Form.Item {...defaultFormItemLayout} label={"Country"}>
                  {getFieldDecorator("coordinator.country")(
                      <CountrySelect placeholder="Country" logoAtr={"Unicode"} />
                  )}
                </Form.Item>
              </Col>*/}

            <Col span={12}>
              <Form.Item {...defaultFormItemLayout} label={"Email"}>
                {getFieldDecorator("coordinator.email")(
                  <Input placeholder="Email" />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <PhoneCountryComponent
              {...{
                form: props.form,
                phonePath: "coordinator.telephoneNo"
              }}
            />

            <PhoneCountryComponent
              {...{
                form: props.form,
                phonePath: "coordinator.mobileNo",
                phoneName: "Mobile Number"
              }}
            />
          </Row>
        </>
      )}
      <Divider />

      <Label text={"Data Privacy Notice Acknowledgement"} />
      <Row>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Name"}>
            {getFieldDecorator("dataPrivacyNoticeAcknowledgement.name", {
              rules: rules.input
            })(<Input placeholder="Name" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Position"}>
            {getFieldDecorator("dataPrivacyNoticeAcknowledgement.position", {
              rules: rules.input
            })(<Input placeholder="Position" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"Date"}>
            {getFieldDecorator("dataPrivacyNoticeAcknowledgement.date", {
              initialValue: moment(),
              rules: rules.select
            })(<DatePicker disabled style={{ width: "100%" }} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <span
          style={{
            margin: "20px 0px",
            display: "block",
            fontWeight: 300,
            maxWidth: "700px!important",
            fontSize: "1.14rem"
          }}
        >
          I hereby acknowledge that all information provided is correct and that
          the person(s) named on this form have read and consented to the terms
          and conditions contained in{" "}
          {isMondiale ?
              "MONDIALE's Privacy Policy viewable by visiting: https://mondiale.com/private-policy.html" :
              isMMEA ?
                  "MMEA's Privacy Policy viewable by visiting: https://mmeagroup.com" :
                  isCleanMist ?
                      "Clean Mist's Privacy Policy viewable by visiting: https://cleanmist.com.au" :
                      isClearFog ?
                          "Clear Fog's Privacy Policy viewable by visiting: https://clearfog.co.uk" :
                          "SOUTER TRADING's Privacy Policy by visiting http://souter-trading.com/privacy.php"}
        </span>
        <Col span={12}>
          <Form.Item {...defaultFormItemLayout} label={"AGREE"}>
            {getFieldDecorator("dataPrivacyNoticeAcknowledgement.hasAgreed", {
              rules: rules.input
            })(<Checkbox placeholder="AGREE" />)}
          </Form.Item>
        </Col>
      </Row>
      <Button
        on
        type={"primary"}
        icon={"plus"}
        loading={submitting}
        onClick={submit}
      >
        Submit
      </Button>
    </Form>
  );
}

export default Form.create()(Client);
