export const rules = {
  select: [{required: true, message: 'Please select an option!'}],
  input: [{required: true, message: 'It can\'t be empty!'}],
  date: [{required: true, message: 'Please select a date!'}],
  moreThanZero: [
    {
      type: 'number',
      required: true,
      validator: (rule, value, callback) => {
        if (Number(value) < 0) callback('Less than 0')
        callback()
      },
    },
  ],
  equalToZero: [
    {
      type: 'number',
      required: true,
      validator: (rule, value, callback) => {
        if (Number(value) !== 0) callback('Not zero')
        callback()
      },
    },
  ],
}
export const defaultFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
    xxl: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
    xxl: {span: 18},
  },
}
export const threeFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
    xxl: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
    xxl: {span: 18},
  },
}

export const fullWidthFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 4},
    xxl: {span: 3},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 20},
    xxl: {span: 21},
  },
}

export const TwoPartThreeColFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 4},
    xxl: {span: 3},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 20},
    xxl: {span: 21},
  },
}
